<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full mx-auto self-center">
        <vs-popup class="holamundo" title="Print Invoice" :active.sync="modal.active">
          <div class="vx-row" data-vv-scope="item-modal">
            <div class="vx-col w-full mt-5">
              <vs-table stripe :data="items">
                <template slot-scope="{data}">
                  <vs-tr :key="index" v-for="(tr, index) in data" :data="tr">
                    <vs-td>
                      <vs-checkbox v-model="modal.form.items" :vs-value="index" color="teal" />
                    </vs-td>
                    <vs-td>
                      <vs-td>{{ tr.brand }} {{ tr.model }} - {{ generateDetail(tr) }}</vs-td>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
            <div class="vx-col w-full mt-5">
              <vs-checkbox v-model="modal.form.is_qr" color="teal">
                With QR
              </vs-checkbox>
            </div>
          </div>
          <vs-divider />
          <div class="vx-col w-full mt-5">
            <vs-button color="teal" type="filled" class="w-full" @click="print" icon="print">
              Print
            </vs-button>
          </div>
        </vs-popup>
        <vx-card :title="invoiceNumber" class="mb-base">
          <div class="vx-row" id="invoice-detail">
            <div class="vx-col flex-1" id="account-info-col-1">
              <table>
                <tr>
                  <td class="font-semibold">
                    Internal No
                  </td>
                  <td>{{ invoice.invoice_internal }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    Date
                  </td>
                  <td>{{ invoiceDate }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    Status
                  </td>
                  <td>
                    <vs-chip :color="status_color[invoice.status]">
                      {{ status_name[invoice.status] }}
                    </vs-chip>
                  </td>
                </tr>
              </table>
            </div>
            <div class="vx-col flex-1" id="account-info-col-2">
              <table>
                <tr>
                  <td class="font-semibold">
                    Branch
                  </td>
                  <td>{{ branch.name }}</td>
                </tr>
                <tr @click="() => this.$router.push(`/customers/${customer.id}`)">
                  <td class="font-semibold">
                    Name
                  </td>
                  <td>{{ customer.name }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    Phone
                  </td>
                  <td>{{ customer.phone }}</td>
                </tr>
              </table>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mx-auto self-center">
        <vx-card>
          <div class="vs-con-loading__container" id="div-with-loading">
            <vs-table stripe :data="items" @selected="loadItemDetail">
              <template slot="thead">
                <vs-th>Item</vs-th>
                <vs-th>Detail</vs-th>
                <vs-th>Status</vs-th>
                <vs-th>Treatments</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="index" v-for="(tr, index) in data" :data="tr">
                  <vs-td>
                    {{ tr.brand }} {{ tr.model }} ({{ tr.type }})
                  </vs-td>

                  <vs-td>
                    <vs-td>{{ generateDetail(tr) }}</vs-td>
                  </vs-td>

                  <vs-td>
                    <vs-chip :color="status_color[tr.status]">
                      {{ status_name[tr.status] }}
                    </vs-chip>
                  </vs-td>

                  <vs-td>
                    <vs-chip
                      v-for="(item, ind) in tr.treatments"
                      color="teal"
                      :key="ind"
                    >
                      {{ `${item.name}` }}
                    </vs-chip>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <div slot="footer">
            <div class="flex justify-between">
              <vs-button color="primary" type="filled" to="/invoices">
                Back
              </vs-button>
              <vs-button color="success" type="filled" @click="message" icon="chat" />
              <vs-button color="teal" type="filled" @click="() => { modal.active = true }" icon="print">
                Print
              </vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <vs-popup class="holamundo" :title="selected_item.brand + ' ' + selected_item.model" :active.sync="popupActivo">
      <vs-list>
        <vs-list-item v-for="(item, ind) in selected_item.treatments" color="teal" :key="ind + '2'" :title="item.name" :subtitle="`${item.technician || '-'}`">
          <vs-chip :color="status_color[item.status]">
            {{ status_name[item.status] }}
          </vs-chip>
        </vs-list-item>
      </vs-list>
      <vs-list>
        <vs-list-item title="Notes" :subtitle="selected_item.notes || '-'" />
      </vs-list>
    </vs-popup>
  </div>
</template>

<script>
import moment from 'moment';
import InvoiceRepo from '@/repositories/invoice_repo';

import { print as bluetoothPrint } from '@/services/print_service';
import { buildItemInvoiceBuffer } from '@/utils/printer';
import { INVOICE_STATUS_NAME, STATUS_COLOR } from '@/utils/constants';

export default {
  data: () => ({
    popupActivo: false,
    selected_item: {
      item: '',
      item_detail: '',
      invoice_no: '',
      technician: {
        name: ''
      },
      notes: '',
      treatments: []
    },
    status_name: INVOICE_STATUS_NAME,
    status_color: STATUS_COLOR,
    customer: {
      id: null,
      name: null,
      phone: null
    },
    invoice: {
      id: null,
      invoice_no: null,
      invoice_internal: null,
      invoice_date: null,
      status: null
    },
    items: [],
    branch: {
      name: ''
    },
    modal: {
      active: false,
      form: {
        n_copies: 1,
        is_qr: false,
        items: []
      }
    }
  }),
  computed: {
    invoiceNumber() {
      return `${this.invoice.invoice_no}`;
    },
    invoiceDate() {
      return moment(this.invoice.invoice_date).format('DD / MM / YYYY');
    },
    clearance() {
      return this.$store.getters.getClearance;
    }
  },
  methods: {
    async loadData() {
      return InvoiceRepo.detail(this.$route.params.id)
        .then(({
          data: {
            invoice, items, branch, customer
          }
        }) => {
          this.invoice = {
            id: invoice.id,
            invoice_no: invoice.invoice_no,
            invoice_internal: invoice.invoice_internal,
            invoice_date: invoice.invoice_date,
            status: invoice.status
          };
          this.customer = {
            id: customer.id,
            name: customer.name,
            phone: customer.phone
          };
          this.branch.name = branch.name;
          this.items = items;
          this.modal.form.items = items.map((data, index) => index);
        });
    },
    print() {
      const buffers = this.items.filter((i, index) => this.modal.form.items.includes(index)).map((item) => {
        const treatments = item.treatments.map(item => item.name).join('|');
        const treatmentIds = item.treatments.map(item => item.id).join('||');
        const { notes } = item.treatments[0];
        return buildItemInvoiceBuffer({
          ...item,
          name: this.customer.name,
          invoice_no: this.invoice.invoice_no,
          invoice_item_internal: item.invoice_item_internal,
          date: moment(this.invoice.invoice_date).format('DD/MM/YYYY'),
          branch: this.branch.name,
          treatments,
          treatment_ids: treatmentIds,
          notes
        }, this.modal.form.is_qr);
      });
      bluetoothPrint(buffers);
    },
    message() {
      const currentHour = moment().format('HH');
      let dayTime = '';

      if (currentHour >= 10 && currentHour < 15) {
        dayTime = 'Siang';
      } else if (currentHour >= 15 && currentHour < 19) {
        dayTime = 'Sore';
      } else if (currentHour >= 19 && currentHour < 23) {
        dayTime = 'Malam';
      } else {
        dayTime = 'Halo';
      }

      const phone = `62${this.customer.phone.slice(1)}`;
      const text = `${dayTime} kak, sepatunya udah bisa diambil yah 😊🙏🏼`;
      window.open(`https://api.whatsapp.com/send/?phone=${phone}&text=${text}&app_absent=0`, '_blank');
    },
    generateDetail(p) {
      return `${[p.material, p.color, p.size, p.lace, p.insole].filter(i => i && i !== '-').join(' / ')}`;
    },
    loadItemDetail(item) {
      this.selected_item = item;
      this.popupActivo = true;
    }
  },
  async mounted() {
    await this.loadData();
  }
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#invoice-detail {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

}

</style>
