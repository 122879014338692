let instance;

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const onPrinterDisconnect = () => {
  console.info('PRINTER DISCONNETTED'); // eslint-disable-line
  instance = null;
};

const initialize = () => navigator.bluetooth.requestDevice(
  { filters: [{ services: ['000018f0-0000-1000-8000-00805f9b34fb'] }] },
  { optionalServices: ['00002af1-0000-1000-8000-00805f9b34fb', '00002af0-0000-1000-8000-00805f9b34fb'] }
).then((device) => {
  device.addEventListener('gattserverdisconnected', onPrinterDisconnect);
  console.info('GETTING DEVICE'); // eslint-disable-line
  instance = device;
});

const getPrintInstance = async () => {
  try {
    if (!instance) {
      await initialize();
    }
    console.info('CONNECTING TO PRINTER'); // eslint-disable-line
    return instance.gatt.connect()
      .then((server) => {
        console.info('GETTING PRIMARY SERVICE'); // eslint-disable-line
        return server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb');
      })
      .then((service) => {
        console.info('GETTING CHARACTERISTIC'); // eslint-disable-line
        return service.getCharacteristic('00002af1-0000-1000-8000-00805f9b34fb');
      });
  } catch (err) {
    console.info(`fail getting service, ${err.message}`); // eslint-disable-line
  }
};

export const print = async buffers => getPrintInstance()
  .then(async (characteristic) => {
    if (buffers instanceof Array) {
      for (let i = 0; i < buffers.length; i++) {
        await characteristic.writeValue(Buffer.from(buffers[i])).then(() => sleep(2000)); // eslint-disable-line
      }
    } else {
      await characteristic.writeValue(Buffer.from(buffers));
    }
  })
  .catch((err) => {
    console.info(`fail printing, ${err.message}`); // eslint-disable-line
  })
  .finally(() => {
    console.info('PRINTING COMPLETED'); // eslint-disable-line
  });

export default {
  print
};
