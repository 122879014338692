import configs from './config';

/**
 *
 * @param {string} str
 * @param {*} settings
 * @returns {Buffer}
 */
export const printQR = (str, settings) => {
  const buffer = [];
  settings = settings || {};

  if (settings.model) {
    if (settings.model === 1) buffer.push(configs.QRCODE_MODEL1);
    else if (settings.model === 3) buffer.push(configs.QRCODE_MODEL3);
    else buffer.push(configs.QRCODE_MODEL2);
  } else {
    buffer.push(configs.QRCODE_MODEL2);
  }

  if (settings.cellSize) {
    const i = 'QRCODE_CELLSIZE_'.concat(settings.cellSize.toString());
    buffer.push(configs[i]);
  } else {
    buffer.push(configs.QRCODE_CELLSIZE_8);
  }

  if (settings.correction) {
    const i = 'QRCODE_CORRECTION_'.concat(settings.correction.toUpperCase());
    buffer.push(configs[i]);
  } else {
    buffer.push(configs.QRCODE_CORRECTION_M);
  }

  const s = str.length + 3;
  const lsb = parseInt(s % 256);
  const msb = parseInt(s / 256);
  buffer.push(Buffer.from([0x1d, 0x28, 0x6b, lsb, msb, 0x31, 0x50, 0x30]));
  buffer.push(Buffer.from(str));
  buffer.push(configs.QRCODE_PRINT);

  return Buffer.concat(buffer);
};

/**
 * @returns {Buffer}
 */
export const newLine = () => configs.CTL_LF;

export default {
  printQR
};
