import { EscPos } from 'escpos-xml';
import { printQR, newLine } from './utility';

const invoiceItemTemplate = `
<?xml version="1.0" encoding="UTF-8"?>
<document>
    <line-feed />
    <align mode="center">
        <bold>
            <text-line size="1:1">SAC {{ branch }}</text-line>
        </bold>
    </align>
    <line-feed />
      <small>
        <text-line>Date      : {{ date }}</text-line>
        <text-line>Receipt   : {{ invoice_no }}</text-line>
        <text-line>Name      : {{ name }}</text-line>
        <text-line>Item      : {{ shoes }}</text-line>
        <text-line>Material  : {{ material }}</text-line>
        <text-line>Color     : {{ color }}</text-line>
        <text-line>Laces     : {{ lace }}</text-line>
        <text-line>Size      : {{ size }}</text-line>
        <text-line>Insole    : {{ insole }}</text-line>
        <text-line>Treatment : {{ treatments }}</text-line>
        <text-line>Notes     : {{ notes }}</text-line>
      <small />
    <line-feed />
</document>
`;

/**
 *
 * @param {*} item
 * @returns {Buffer}
 */
export const buildItemInvoiceBuffer = (item, withQr) => {
  const data = {
    invoice_no: item.invoice_no,
    invoice_item_internal: item.invoice_item_internal,
    date: item.date,
    branch: item.branch,
    name: item.name,
    shoes: `${item.brand} ${item.model}`,
    size: item.size || '-',
    color: item.color || '-',
    material: item.material || '-',
    lace: item.lace || '-',
    insole: item.insole || '-',
    treatments: item.treatments,
    notes: item.notes || '-'
  };

  const templateBuffer = Buffer.from(EscPos.getBufferFromTemplate(invoiceItemTemplate, data));
  const newLineBuffer = newLine();

  if (!withQr) {
    return Buffer.concat([templateBuffer, newLineBuffer]);
  }

  const qrCodeBuffer = printQR(item.treatment_ids);
  return Buffer.concat([templateBuffer, qrCodeBuffer, newLineBuffer, newLineBuffer, newLineBuffer]);
};

export default {
  buildItemInvoiceBuffer
};
